/*********************************************
:: Variables                      
********************************************/
//Mappy Breakpoints: https://github.com/zellwk/mappy-breakpoints
$breakpoints: (
  tiny: 350px,
  small: 450px,
  smallish: 600px,
  medium: 768px,
  mediumish: 1024px,
  large: 1160px,
  largeish: 1305px,
  huge: 1440px, 
  hugeish: 1680px, 
  bigmac: 1920px
);

//Breakpoint Sizes
$huge: 1440px;
$large: 1160px;
$mediumish: 1024px;
$tablet: 600px;
$iphone5: 350px;

/*******************************************/
//Fonts

// Base Fonts
$font-family-1: 'Roboto', sans-serif;
$font-family-2: 'Nunito', sans-serif;
$font-icon: "icomoon";

// Fonts Weights
$font-light: 300;
$font-normal: 400;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 900;

/*******************************************/
//Colors

// Brand Colors
$color-1: #0fb9d1; //light blue
$color-2: #16314e; //dark blue
$color-3: #ccd851; //green
$color-4: #0fb9d1; //light blue secondary
$color-5: #0c3150; //light text

$color-6: #2c6c92; //medium blue
$color-7: #c8da2b; //medium blue
$color-9: #006d96;

// Shades of Grey
$color-grey: #0d0d0d;
$color-medium-grey: #f6f6f6;
$color-dark-grey: #0d0d0d;
$color-light-grey: #f5f5f5;
$color-lightest-grey: #ccc;
$color-text-grey: #848484;

// Links
$color-link: $color-1;
$color-link-hover: darken($color-link, 12%);

// User Feedback
$color-message: $color-medium-grey;
$color-attention: $color-1;
$color-success: #38B449;

// Social Media
$color-facebook: #3b5998;
$color-twitter: #55acee;
$color-vimeo: #1ab7ea;
$color-youtube: #cd201f;
$color-gplus: #DC4E41;

/*******************************************/
//Page Height Calculator
$header-height-desktop: 135px;
$header-height-mobile: 65px;

$footer-height-desktop: 330px;
$footer-height-mobile: 300px;

//*******************************************/
//Placeholder image

$placeholder-image: "https://www.scandichotels.com/Static/img/placeholders/image-placeholder_3x2.svg";