/*********************************************
:: Site Footer
********************************************/
.site-footer {
    height: $footer-height-desktop;
    @include mappy-bp(max-width mediumish) {
        height: $footer-height-mobile;
        display: inline-block;
        width: 100%;
    }
    .grid-max-width {
        background-color: $color-2;
        width: 100%;
        max-width: 100%;
        .grid-content {
            padding-top: 55px;
            height: $footer-height-desktop;
            @include mappy-bp(max-width mediumish) {
                height: $footer-height-mobile;
                padding-top: 40px;
            }
            @include mappy-bp(mediumish largeish) {
                padding-left: 30px;
                padding-right: 30px;
            }
            .footer-content {
                display: flex;
                height: 240px;
                @include mappy-bp(max-width mediumish) {
                    display: block;
                    width: 100%;
                    text-align: center;
                    height: 200px;
                }
                .footer-section {
                    &:not(:first-child):not(:last-child) {
                        @include mappy-bp(max-width mediumish) {
                            display: none;
                        }
                    }
                    .heading {
                        color: #c8da2b;
                        font-family: $font-family-2;
                        font-size: 14px;
                        font-weight: 700;
                        margin-bottom: 2px;
                    }
                    ul {
                        padding: 0;
                        margin: 0;
                        li {
                            padding: 0;
                            margin: 0;
                            a {
                                color: #ffffff;
                                font-family: $font-family-2;
                                font-size: 12px;
                                font-weight: 600;
                            }
                        }
                    }
                    &:nth-child(1) {
                        width: 301px;
                        @include mappy-bp(max-width mediumish) {
                            width: 100%;
                            margin-bottom: 20px;
                        }
                        img {
                            width: 143px;
                            height: 45px;
                            position: relative;
                            left: 2px;
                            @include mappy-bp(max-width mediumish) {
                                width: 110px;
                                height: 37px;
                            }
                        }
                    }
                    &:nth-child(2) {
                        width: 202px;
                        ul {
                            li {
                                height: 22px;
                            }
                        }
                    }
                    &:nth-child(3) {
                        width: 202px;
                        ul {
                            margin-top: -3px;
                            li {
                                height: 22px;
                            }
                        }
                    }
                    &:nth-child(4) {
                        width: 202px;
                        margin-top: -2px;
                        .heading {
                            margin-bottom: 6px;
                            margin-left: -1px;
                            a {
                                color: $color-3;
                                font-family: $font-family-2;
                                font-size: 14px;
                                font-weight: 700;
                                margin-bottom: 2px;
                            }
                        }
                    }
                    &:nth-child(5) {
                        width: 267px;
                        @include mappy-bp(max-width mediumish) {
                            margin: auto;
                        }
                        a {
                            text-align: right;
                        }
                        .phone {
                            height: 26px;
                            color: #ffffff;
                            font-family: $font-family-1;
                            font-size: 22px;
                            font-weight: 700;
                            text-align: right;
                            position: relative;
                            right: 0;
                            margin-bottom: 12px;
                            margin-right: 2px;
                            @include mappy-bp(max-width mediumish) {
                                left: 0;
                                right: 0;
                                margin: auto;
                                text-align: center;
                                margin-bottom: 20px;
                            }
                        }
                        .email {
                            width: 100%;
                            height: 23px;
                            color: $color-1;
                            font-family: $font-family-1;
                            font-size: 20px;
                            font-weight: 400;
                            padding-right: 2px;
                            @include mappy-bp(max-width mediumish) {
                                left: 0;
                                right: 0;
                                margin: auto;
                                margin-bottom: 20px;
                            }
                        }
                        .social-media {
                            display: flex;
                            justify-content: flex-end;
                            margin-top: 20px;
                            @include mappy-bp(max-width mediumish) {
                                justify-content: center;
                            }
                            .facebook {
                                margin-right: 10px;
                                @include mappy-bp(max-width mediumish) {
                                    margin-right: 30px;
                                }
                            }
                            .linkedin {

                            }
                        }
                    }
                }
            }
            .footer-legal {
                .content-max-width {
                    display: flex;
                    position: relative;
                    @include mappy-bp(max-width mediumish) {
                        display: block;
                        width: 100%;
                        margin-bottom: 15px;
                    }
                    .copyright {
                        height: 15px;
                        color: #ffffff;
                        font-family: $font-family-2;
                        font-size: 11px;
                        font-weight: 300;
                        position: relative;
                        left: 2px;
                        top: -1px;
                        margin-bottom: 15px;

                        @include mappy-bp(max-width mediumish) {
                            text-align: center;
                            right: 0;
                            left: 0;
                            margin: auto;
                        }
                    }
                    a {
                        position: absolute;
                        right: 0;
                        width: 167px;
                        height: 15px;
                        color: #ffffff;
                        font-family: $font-family-2;
                        font-size: 11px;
                        font-weight: 300;

                        @include mappy-bp(max-width mediumish) {
                            text-align: center;
                            right: 0;
                            left: 0;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}
