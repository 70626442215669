/*********************************************
:: Default
********************************************/

.page-default {
    .page-heading {
        h1 {
            right: 30px;
            top: 25px;
            
            @include mappy-bp(medium mediumish) {
                color: #ffffff;
                font-size: 24px;
                font-weight: 900;
                line-height: 25px;
                right: 25px;
                top: 17px;
            }
            @include mappy-bp(max-width medium) {
                right: 16px;
                top: 10px;
            }
        }
    }
    .grid-content {
        margin-top: 60px;
        @include mappy-bp(max-width mediumish) {
            margin-top: 25px;
        }
    }
    .content {
        width: 970px;
        margin: auto;
        margin-bottom: 56px;
        @include mappy-bp(max-width mediumish) {
            width: 100%;
            display: block;
            padding-left: 29px;
            padding-right: 29px;
        }
        p {
            color: #000000;
            font-family: $font-family-2;
            font-size: 17px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: 0.3px;
            margin-bottom: 24px;
            @include mappy-bp(max-width mediumish) {
                font-size: 14px;
                font-weight: 300;
                line-height: 21px;
                letter-spacing: 0.25px;
                margin-bottom: 21px;
            }
        }
        h1, h2, h3, h4, h5 {
            color: $color-4;
            font-family: $font-family-2 !important;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-bottom: 4px;
            @include mappy-bp(max-width mediumish) {
                font-size: 15px;
                font-weight: 400;
                line-height: 23px;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-bottom: 5px;
            }
        }
        ul {
            padding: 0;
            margin-left: 19px;
            margin-bottom: 25px;
            margin-top: -5px;
            li {
                list-style-type: disc;
                color: #232323;
                font-family: $font-family-2;
                font-size: 16px;
                font-weight: 400;
                line-height: 27px;
                letter-spacing: 0.3px;
                margin: 0;
                padding-left: 3px;
                padding-top: 1px;
                padding-bottom: 0;
            }
        }
    }
}