/*********************************************
:: Contact Page                   
********************************************/
.page-contact {
    display: block;
    .map-area {
        display: inline-block;
        margin: auto auto -6px 0;
        width: 100%;
        @include mappy-bp(max-width mediumish) {
            display: none;
        }
        .map {
            float: left;
            margin: auto;
            width: 100%;
            height: 352px;
        }
    }
    .btn-show-map {
        display: none;
        @include mappy-bp(max-width mediumish) {
            display: block;
            width: 124px;
            height: 38px;
            border: 1px solid $color-5;
            background-color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .text {
            width: 81px;
            height: 15px;
            color: $color-5;
            font-family: $font-family-2;
            font-size: 12px;
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .map-pop-up {
        display: none;
        position: absolute;
        top: 325px;
        z-index: 100;
        width: 1220px;
        min-height: 435px;
        background-color: #fff;
        padding-bottom: 80px;

        left: 0;
        right: 0;
        margin: auto;

        @include mappy-bp(max-width mediumish){
            width: 82%;
            position: absolute;
            top: 170px;
            left: 0;
            right: 0;
            margin: auto;
        }

        @include mappy-bp(mediumish huge){
            width: 90%;
            position: absolute;
            top: 170px;
            left: 5%;
        }
        .map {
            float: left;
            margin: auto;
            width: 100%;
            height: 352px;
        }
        .close {
            color: $color-lightest-grey;
            font-size: 40px;
            position: absolute;
            right: 25px;
            top: 30px;
            cursor: pointer;
            z-index: 991;
            @include mappy-bp(max-width mediumish){
                font-size: 20px;
                right: -20px;
                top: -20px;
            }
        }
    }
    .page-heading {
        h1 {
            right: 31px;
            @include mappy-bp(max-width mediumish) {
                right: 16px;
            }
        }
    }
    .contact-section {
        display: flex;
        width: 970px;
        margin: auto;
        margin-top: 61px;
        margin-bottom: 36px;
        @include mappy-bp(max-width mediumish) {
            width: 100%;
            display: block;
            margin: auto;
            margin-top: 25px;
        }
        .grid-l {
            width: 400px;
            @include mappy-bp(max-width mediumish) {
                width: 100%;
                display: block;
                margin-bottom: 35px;
            }
            .phones {
                margin-bottom: 25px;
                @include mappy-bp(max-width mediumish) {
                    margin-bottom: 21px;
                }
                .phone {
                    a {
                        color: $color-5;
                        font-family: $font-family-1;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 39px;
                        @include mappy-bp(max-width mediumish) {
                            font-size: 23px;
                            font-weight: 700;
                            line-height: 45px;
                        }
                    }
                }
                .phone-secondary {
                    a {
                        color: $color-4;
                        font-family: $font-family-1;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 39px;
                        @include mappy-bp(max-width mediumish) {
                            font-size: 23px;
                            font-weight: 700;
                            line-height: 45px;
                        }
                    }
                }
            }
            .email {
                margin-bottom: 30px;
                a {
                    color: $color-5;
                    font-family: $font-family-2;
                    font-size: 23px;
                    font-weight: 400;
                    line-height: 30px;
                    @include mappy-bp(max-width mediumish) {
                        font-size: 21px;
                        font-weight: 400;
                        line-height: 30px;
                    }
                }
            }
            .address-label {
                color: $color-4;
                font-family: $font-family-2;
                font-size: 15px;
                font-weight: 400;
                line-height: 22px;
                text-transform: uppercase !important;
                letter-spacing: 2px;
                @include mappy-bp(max-width mediumish) {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 23px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }
            }
            .address {
                margin-top: 4px;
                @include mappy-bp(max-width mediumish) {
                    margin-bottom: 20px;
                }
                .content {
                    width: 213px;
                    color: #000000;
                    font-family: $font-family-2;
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: 0.3px;
                    @include mappy-bp(max-width mediumish) {
                        width: 100%;
                        display: block;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 21px;
                        letter-spacing: 0.25px;
                    }
                }
            }
        }
        .grid-r {
            width: 570px;
            margin: auto;
            @include mappy-bp(max-width mediumish) {
                width: 100%;
                display: block;
            }
            .two-column {
                .form-field {
                    @include mappy-bp(max-width mediumish) {
                        width: 100%;
                        display: block;
                    }
                    &:nth-child(1) {
                        width: 247px;
                        @include mappy-bp(max-width mediumish) {
                            width: 100%;
                            display: block;
                        }
                    }
                    &:nth-child(2) {
                        width: 311px;
                        @include mappy-bp(max-width mediumish) {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
            label {
                color: #000000;
                font-family: $font-family-2;
                font-size: 13px;
                font-weight: 300;
                line-height: 20px;
                margin-bottom: 3px;
            }
            .contact-form {
                margin-top: -2px;
            }
            .form-field {
                margin-bottom: 0px;
                padding-bottom: 14px;
            }
            .form-submit {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 92px;
                height: 38px;
                background-color: $color-7;
                a {
                    color: #ffffff;
                    font-family: $font-family-2;
                    font-size: 12px;
                    font-weight: 900;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    position: relative;
                    left: -1px;
                    width: 100%;
                }
            }
        }
    }
}