/*********************************************
:: Home Page                   
********************************************/
.page-home {
    min-height: 800px;
    // max-width: $huge;
    @include mappy-bp(max-width mediumish) {
    }
    .home-max-width {
        width: 1305px;
        position: relative;
        margin: auto auto 100px;
        @include mappy-bp(mediumish largeish) {
            width: 100%;
            min-height: 250px;
            margin-bottom: 30px;
        }
        @include mappy-bp(medium mediumish) {
            width: 100%;
            min-height: 250px;
            margin-bottom: 30px;
        }
        @include mappy-bp(max-width medium) {
            width: 100%;
            min-height: 250px;
            margin-bottom: 30px;
        }
    }
    .hero-section {
        margin: auto auto 100px;
        @include mappy-bp(medium mediumish) {
            margin-bottom: 30px;
        }
    }
    .hero-banner {
        @include mappy-bp(mediumish largeish) {
            height: 560px;
        }
        @include mappy-bp(medium mediumish) {
            height: 360px;
        }
        @include mappy-bp(max-width medium) {
            height: 260px;
        }
        .grid {
            display: flex;
            @include mappy-bp(max-width mediumish) {
                padding: 0;
            }
            .grid-l {
                width: 49%;
                height: 650px;
                display: flex;
                justify-content: center;
                align-content: center;
                position: relative;
                background-color: $color-1;
                @include mappy-bp(mediumish largeish) {
                    width: 55%;
                    height: 560px;
                }
                @include mappy-bp(medium mediumish) {
                    width: 55%;
                    height: 360px;
                }
                @include mappy-bp(max-width medium) {
                    width: 55%;
                    height: 260px;
                }
                .content {
                    width: 435px;
                    height: 203px;
                    position: absolute;
                    top: 223px;
                    margin: auto;
                    left: unset;
                    right: 136px;
                    @include mappy-bp(mediumish largeish) {
                        top: 170px;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                    @include mappy-bp(medium mediumish) {
                        top: 120px;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                    @include mappy-bp(max-width medium) {
                        width: 145px;
                        height: 203px;
                        top: 87px;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }
                    .text {
                        text-align: center;
                        letter-spacing: -1px;
                        position: relative;
                        p {
                            color: #ffffff;
                            font-family: $font-family-1;
                            font-size: 56px;
                            font-weight: 900;
                            line-height: 68px;
                            @include mappy-bp(medium mediumish) {
                                font-family: $font-family-1;
                                font-size: 34px;
                                font-weight: 900;
                                line-height: 36px;
                                letter-spacing: -1px;
                            }
                            @include mappy-bp(max-width medium) {
                                font-family: $font-family-1;
                                font-size: 24px;
                                font-weight: 900;
                                line-height: 29px;
                                letter-spacing: -1px;
                            }
                        }
                    }
                    .top-bracket {
                        position: absolute;
                        top: -123px;
                        transform: rotateX(180deg);
                        @include mappy-bp(medium mediumish) {
                            width: 220px;
                            left: 0;
                            right: 0;
                            top: -75px;
                            margin: auto;
                        }
                        @include mappy-bp(max-width medium) {
                            width: 145px;
                            top: -40px;
                        }
                    }
                    .bottom-bracket {
                        position: absolute;
                        bottom: -124px;
                        @include mappy-bp(medium mediumish) {
                            width: 220px;
                            left: 0;
                            right: 0;
                            bottom: 10px;
                            margin: auto;
                        }
                        @include mappy-bp(max-width medium) {
                            width: 145px;
                            bottom: 75px;
                        }
                    }
                }
            }
            .grid-r {
                width: 51%;
                height: 650px;
                display: inline-block;
                background-repeat: no-repeat;
                background-position: left;
                @include mappy-bp(mediumish largeish) {
                    width: 45%;
                    height: 560px;
                    background-size: cover;
                    background-position: center;
                }
                @include mappy-bp(medium mediumish) {
                    width: 45%;
                    height: 360px;
                    background-size: cover;
                    background-position: center;
                }
                @include mappy-bp(max-width medium) {
                    width: 45%;
                    height: 260px;
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }
    .secondary-section {
        margin-bottom: 100px;
        @include mappy-bp(max-width mediumish) {
            margin-bottom: 40px;
        }
        .grid {
            display: flex;
            position: relative;
            @include mappy-bp(mediumish largeish) {
                width: 100%;
                display: flex;
            }
            @include mappy-bp(medium mediumish) {
                width: 100%;
                display: block;
            }
            @include mappy-bp(max-width medium) {
                width: 100%;
                display: block;
            }
            .grid-l {
                position: relative;
                width: 58.5%;
                @include mappy-bp(mediumish largeish) {
                    width: 55%;
                }
                @include mappy-bp(medium mediumish) {
                    width: 100%;
                    display: block;
                }
                @include mappy-bp(max-width medium) {
                    width: 100%;
                    display: block;
                }
                .content {
                    position: absolute;
                    left: 135px;
                    @include mappy-bp(mediumish largeish) {
                        left: 30px;
                        top: 55px;
                    }
                    @include mappy-bp(medium mediumish) {
                        left: 0px;
                        position: relative;
                        margin-bottom: 30px;
                    }
                    @include mappy-bp(max-width medium) {
                        left: 0px;
                        position: relative;
                        margin-bottom: 30px;
                    }
                    .secondary-heading {
                        width: 370px;
                        height: 84px;
                        margin-bottom: 71px;
                        p {
                            font-size: 36px;
                            line-height: 42px;
                            font-weight: 900;
                            font-family: $font-family-1;
                            color: $color-2;
                            @include mappy-bp(max-width medium) {
                                font-size: 22px;
                                font-weight: 900;
                                line-height: 28px;
                                margin-top: 10px;
                                width: 100%;
                                height: inherit;
                            }
                        }
                        strong {
                            color: $color-1;
                            background: url(/images/svg/all-bracket.svg);
                            background-repeat: no-repeat;
                            background-position: bottom;
                            background-size: contain;
                            width: 50px;
                            display: inline-block;
                            text-align: center;
                            @include mappy-bp(max-width medium) {
                                width: 30px;
                                margin-right: -4px;
                            }
                        }
                        @include mappy-bp(mediumish largeish) {
                            left: 0px;
                        }
                        @include mappy-bp(medium mediumish) {
                            left: 0px;
                            margin-bottom: 20px;
                        }
                        @include mappy-bp(max-width medium) {
                            display: block;
                            font-size: 22px;
                            font-weight: 900;
                            line-height: 28px;
                            left: 0;
                            position: relative;
                            margin-top: 10px;
                            width: 100%;
                            height: inherit;
                            margin-bottom: 24px;
                            width: 240px;
                        }
                    }
                    .secondary-content {
                        width: 457px;
                        height: 168px;
                        position: relative;
                        left: 100px;
                        p {
                            color: $color-5;
                            font-family: $font-family-2;
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 28px;
                            @include mappy-bp(max-width medium) {
                                width: 100%;
                                display: block;
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 22px;
                                position: relative;
                            }
                        }
                        @include mappy-bp(mediumish largeish) {
                            left: 20px;
                        }
                        @include mappy-bp(medium mediumish) {
                            left: 0px;
                            width: 100%;
                            height: inherit;
                        }
                        @include mappy-bp(max-width medium) {
                            width: 100%;
                            display: block;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 22px;
                            position: relative;
                            left: 0;
                            height: auto;
                        }
                    }
                }
            }
            .grid-r {
                width: 470px;
                @include mappy-bp(mediumish largeish) {
                    width: 45%;
                }
                @include mappy-bp(medium mediumish) {
                    width: 100%;
                    display: block;
                }
                @include mappy-bp(max-width medium) {
                    width: 100%;
                }
                .block-container {
                    width: 470px;
                    height: 490px;
                    background-color: $color-2;
                    display: flex;
                    flex-wrap: wrap;
                    @include mappy-bp(mediumish largeish) {
                        margin: auto;
                        width: 454px;
                    }
                    @include mappy-bp(medium mediumish) {
                        margin: auto;
                        width: 454px;
                    }
                    @include mappy-bp(max-width medium) {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        width: 314px;
                        height: 320px;
                        margin: auto;
                    }
                    @include mappy-bp(max-width tiny) {
                        width: 100%;
                    }
                }
                .blocks {
                    width: 235px;
                    height: 245px;
                    position: relative;
                    @include mappy-bp(medium largeish) {
                        margin: auto;
                        width: 227px;
                        height: 245px;
                    }
                    @include mappy-bp(medium mediumish) {
                        margin: auto;
                        width: 227px;
                        height: 245px;
                    }
                    @include mappy-bp(max-width medium) {
                        width: 157px;
                        height: 160px;
                        display: block;
                    }
                    @include mappy-bp(max-width tiny) {
                        width: 50%;
                        height: 50%;
                        display: block;
                    }
                    a {
                        height: 100%;
                        width: 100%;
                        display: block;
                    }
                    .text {
                        position: absolute;
                        height: 31px;
                        color: #ffffff;
                        font-family: $font-family-1;
                        font-size: 26px;
                        font-weight: 900;
                        position: absolute;
                        @include mappy-bp(max-width mediumish) {
                            color: #ffffff;
                            font-family: $font-family-1;
                            font-size: 18px;
                            font-weight: 900;
                        }
                    }
                    &.life-and-health {
                        background-color: $color-1;
                        @include mappy-bp(max-width mediumish) {
                            border-right: 1px solid #fff;
                            border-bottom: 1px solid #fff;
                        }
                        .text {
                            left: 20px;
                            bottom: 19px;
                            @include mappy-bp(max-width mediumish) {
                                left: 14px;
                                bottom: 5px;
                            }
                        }
                    }
                    &.financial {
                        background-color: $color-9;
                        .text {
                            right: 21px;
                            bottom: 19px;
                            @include mappy-bp(max-width mediumish) {
                                right: 14px;
                                bottom: 5px;
                            }
                        }
                    }
                    &.personal {
                        background-color: $color-5;
                        .text {
                            left: 20px;
                            bottom: 19px;
                            @include mappy-bp(max-width mediumish) {
                                left: 14px;
                                bottom: 5px;
                            }
                        }
                    }
                    &.business {
                        background-color: $color-7;
                        @include mappy-bp(max-width mediumish) {
                            border-left: 1px solid #fff;
                            border-top: 1px solid #fff;
                        }
                        .text {
                            right: 21px;
                            bottom: 19px;
                            @include mappy-bp(max-width mediumish) {
                                right: 14px;
                                bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    .more-section {
        margin-bottom: 100px;
        @include mappy-bp(max-width mediumish) {
            margin-bottom: 30px;
        }
        .grid {
            display: flex;
            position: relative;
            @include mappy-bp(max-width mediumish) {
                padding: 0;
                display: block;
                width: 100%;
            }
            .grid-l {
                position: relative;
                width: 835px;
                display: flex;
                @include mappy-bp(mediumish largeish) {
                    width: 100%;
                    margin-bottom: 40px;
                }
                @include mappy-bp(medium mediumish) {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 40px;
                }
                @include mappy-bp(max-width medium) {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 40px;
                }
                .image-left-primary {
                    width: 305px;
                    height: 405px;
                    margin-right: 30px;
                    position: relative;
                    top: -100px;
                    background-color: $color-1;
                    background-color: transparent;
                    background-position: top;
                    background-repeat: no-repeat;
                    @include mappy-bp(mediumish largeish) {
                        width: 305px;
                        height: 405px;
                        background-color: transparent;
                        background-size: contain;
                        background-position: top;
                        background-repeat: no-repeat;
                    }
                    @include mappy-bp(medium mediumish) {
                        top: 0px;
                        width: 305px;
                        height: 405px;
                        background-color: transparent;
                        background-size: cover;
                        background-position: top;
                        background-repeat: no-repeat;
                        margin-right: 15px;
                    }
                    @include mappy-bp(max-width medium) {
                        top: 0px;
                        width: 155px;
                        height: 215px;
                        background-color: transparent;
                        background-size: cover;
                        background-position: top;
                        background-repeat: no-repeat;
                        margin-right: 15px;
                    }
                }
                .image-left-secondary {
                    width: 370px;
                    height: 380px;
                    position: relative;
                    top: 21px;
                    @include mappy-bp(mediumish largeish) {
                        width: 370px;
                        height: 380px;
                        background-color: transparent;
                        background-size: contain;
                        background-position: top;
                        background-repeat: no-repeat;
                    }
                    @include mappy-bp(medium mediumish) {
                        top: 0px;
                        width: 370px;
                        height: 405px;
                        background-color: transparent;
                        background-size: cover;
                        background-position: top;
                        background-repeat: no-repeat;
                    }
                    @include mappy-bp(max-width medium) {
                        top: 0px;
                        width: 205px;
                        height: 215px;
                        background-color: transparent;
                        background-size: cover;
                        background-position: top;
                        background-repeat: no-repeat;
                        height: auto;
                    }
                }
            }
            .grid-r {
                width: 470px;
                @include mappy-bp(mediumish largeish) {
                    width: 100%;
                    display: block;
                    padding: 30px;
                }
                @include mappy-bp(medium mediumish) {
                    width: 100%;
                    display: block;
                    padding-left: 30px;
                    padding-right: 30px;
                }
                @include mappy-bp(max-width medium) {
                    width: 100%;
                    display: block;
                    padding-left: 30px;
                    padding-right: 30px;
                }
                .more-primary {
                    width: 365px;
                    position: relative;
                    margin-top: 20px;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #000;
                    margin-bottom: 45px;
                    p {
                        color: $color-1;
                        font-family: $font-family-2;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 30px;
                        position: relative;
                        margin-top: 20px;
                        @include mappy-bp(medium mediumish) {
                            width: 100%;
                            margin-top: 0px;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 23px;
                            padding-bottom: 20px;
                            margin-bottom: 20px;
                        }
                        @include mappy-bp(max-width medium) {
                            width: 100%;
                            margin-top: 0px;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 23px;
                            padding-bottom: 20px;
                            margin-bottom: 20px;
                        }
                    }
                    @include mappy-bp(mediumish largeish) {
                        width: 100%;
                        margin-top: 0px;
                    }
                    @include mappy-bp(medium mediumish) {
                        width: 100%;
                        margin-top: 0px;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 23px;
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                    @include mappy-bp(max-width medium) {
                        width: 100%;
                        margin-top: 0px;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 23px;
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                }
                .more-secondary {
                    width: 365px;
                    p {
                        color: #000000;
                        font-family: $font-family-2;
                        font-size: 17px;
                        font-weight: 300;
                        line-height: 24px;
                        letter-spacing: 0.3px;

                        @include mappy-bp(medium mediumish) {
                            width: 100%;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 21px;
                            letter-spacing: 0.25px;
                        }
                        @include mappy-bp(max-width medium) {
                            width: 100%;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 21px;
                            letter-spacing: 0.25px;
                        }
                    }
                    @include mappy-bp(mediumish largeish) {
                        width: 100%;
                    }
                    @include mappy-bp(medium mediumish) {
                        width: 100%;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 21px;
                        letter-spacing: 0.25px;
                    }
                    @include mappy-bp(max-width medium) {
                        width: 100%;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 21px;
                        letter-spacing: 0.25px;
                    }
                }
            }
        }
    }
    .get-started {
        margin-bottom: 80px;
        width: 100%;
        height: 151px;
        background: url('/images/get-a-quote-bar-desktop.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-1;
        @include mappy-bp(mediumish largeish) {
            background: url('/images/get-a-quote-bar-desktop.png');
            background-size: cover;
        }
        @include mappy-bp(small mediumish) {
            width: 100%;
            height: 151px;
            background: url(/images/get-a-quote-bar-mobile.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 30px;
        }
        @include mappy-bp(small smallish) {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        @include mappy-bp(max-width small) {
            width: 100%;
            height: 85px;
            background: url('/images/get-a-quote-bar-mobile.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-bottom: 30px;
        }
        .text {
            // width: 863px;
            width: 945px;
            color: #ffffff;
            font-family: $font-family-1;
            font-size: 34px;
            font-weight: 900;
            margin-top: -4px;
            margin-left: -3px;
            @include mappy-bp(mediumish largeish) {
                width: 70%;
                color: #ffffff;
                font-size: 34px;
                line-height: 36px;
                font-weight: 900;
                margin-top: -5px;
                margin-left: 100px;
            }
            @include mappy-bp(small mediumish) {
                width: 70%;
                color: #ffffff;
                font-size: 34px;
                line-height: 36px;
                font-weight: 900;
                margin-top: -5px;
                margin-left: 40px;
            }
            @include mappy-bp(small medium) {
                font-size: 18px;
                line-height: 22px;
            }
            @include mappy-bp(tiny small) {
                width: 266px;
                height: 42px;
                color: #ffffff;
                font-size: 18px;
                line-height: 22px;
                font-weight: 900;
                margin-top: -6px;
                margin-left: 30px;
            }
            @include mappy-bp(max-width tiny) {
                margin-left: 25px;
                font-size: 18px;
                margin-top: -6px;
                margin-left: 60px;
            }
            img {
                position: relative;
                top: -5px;
                @include mappy-bp(mediumish largeish) {
                    width: 70px;
                    top: -5px;
                }
                @include mappy-bp(small mediumish) {
                    width: 60px;
                    top: -5px;
                }
                @include mappy-bp(small medium) {
                    width: 46px;
                    top: -3px;
                }
                @include mappy-bp(tiny small) {
                    width: 36px;
                    top: -3px;
                }
                @include mappy-bp(max-width tiny) {
                    width: 35px;
                    top: -3px;
                }
            }
        }
    }
    .insurance-company {
        margin-bottom: 80px;
        @include mappy-bp(max-width mediumish) {
            margin-bottom: 30px;
        }
        .companies {
            width: 85%;
            max-width: 1305px;
            margin: auto;
            .slick-track {
                margin: auto;
            }
            .company {
                height: 85px;
                padding: 10px;
                position: relative;
                img {
                  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                  filter: grayscale(100%);
                  margin: auto;
                  width: 130px;
                  vertical-align: middle;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin-right: -50%;
                  transform: translate(-50%, -50%);
              }
              &.slick-slide {
                img {
                        // width: 80px;
                        width: 70%;
                    }
                }
            }
        }
    }
}
