/*********************************************
:: Site About Us
********************************************/

.page-about-us {
    .grid {
        width: 100%;
        max-width: 1370px;
        min-height: 700px;
        margin: 61px auto 42px;
        display: flex;
        // margin-top: 61px;
        // margin-bottom: 42px;
        position: relative;
        @include mappy-bp(max-width mediumish) {
            display: block;
            width: 100%;
            margin-top: 25px;
        }
        .grid-l {
            width: 935px;
            position: relative;
            @include mappy-bp(mediumish largeish) {
                display: block;
                width: 65%;
            }
            @include mappy-bp(medium mediumish) {
                display: block;
                width: 100%;
            }
            @include mappy-bp(max-width medium) {
            }
            .text {
                width: 570px;
                position: absolute;
                left: 235px;
                @include mappy-bp(mediumish largeish) {
                    left: 50px;
                    position: relative;
                }
                @include mappy-bp(medium mediumish) {
                    left: 0px;
                    position: relative;
                    width: 100%;
                }
                @include mappy-bp(max-width medium) {
                    left: 0px;
                    position: relative;
                    width: 100%;
                }
            }
            .lead-copy {
                color: $color-5;
                font-family: $font-family-2;
                font-size: 20px;
                font-weight: 400;
                line-height: 28px;
                margin-bottom: 23px;
                @include mappy-bp(max-width mediumish) {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    margin: 0;
                }
            }
            .right-content {
                display: block;
                margin-top: 30px;
                // @include mappy-bp(max-width mediumish) {
                //     display: block;
                //     margin-top: 30px;
                // }
                // @include mappy-bp(mediumish bigmac) {
                //     display: none;
                // }
                @media (min-width: $mediumish) {
                    display: none;
	            }
            }
            .image {
                margin-bottom: 40px;
                width: 370px;
                height: 414px;
                margin-top: 29px;
                background-repeat: no-repeat;
                background-size: cover;
                @include mappy-bp(medium mediumish) {
                    display: block;
                    width: 100%;
                    width: 318px;
                    height: 250px;
                    margin: auto auto 25px;
                }
                @include mappy-bp(max-width medium) {
                    max-width: 100%;
                    max-height: 250px;
                    margin: auto auto 25px;
                }
            }
            .side-description {
                width: 370px;
                height: 90px;
                color: $color-1;
                font-family: $font-family-2;
                font-size: 24px;
                font-weight: 600;
                line-height: 30px;
                @include mappy-bp(medium mediumish) {
                    display: block;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 23px;
                    margin-bottom: 30px;
                    height: inherit;
                }
                @include mappy-bp(max-width medium) {
                    display: block;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 23px;
                    margin-bottom: 30px;
                    height: inherit;
                }
            }
            .content {
                p {
                    color: #000000;
                    font-family: $font-family-2;
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: 0.3px;
                    margin-bottom: 24px;
                    @include mappy-bp(max-width mediumish) {
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 21px;
                        letter-spacing: 0.25px;
                    }
                }
            }
        }
        .grid-r {
            width: 370px;
            @include mappy-bp(mediumish largeish) {
                display: block;
                width: 35%;
            }
            @include mappy-bp(medium mediumish) {
                display: block;
                width: 100%;
            }
            @include mappy-bp(max-width medium) {
                display: block;
                width: 100%;
            }
            .right-content {
                @include mappy-bp(max-width mediumish) {
                    display: none;
                }
                @include mappy-bp(min-width mediumish) {
                    display: block;
                }
            }
            .image {
                margin-bottom: 40px;
                width: 370px;
                height: 414px;
                background-repeat: no-repeat;
                background-size: cover;
                @include mappy-bp(medium mediumish) {
                    display: block;
                    width: 100%;
                    width: 318px;
                    height: 250px;
                    margin: auto auto 30px;
                }
                @include mappy-bp(max-width medium) {
                    max-width: 100%;
                    max-height: 250px;
                    margin: auto auto 30px;
                }
            }
            .side-description {
                width: 370px;
                height: 90px;
                color: $color-1;
                font-family: $font-family-2;
                font-size: 24px;
                font-weight: 600;
                line-height: 30px;
                @include mappy-bp(mediumish largeish) {
                }
                @include mappy-bp(medium mediumish) {
                    display: block;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 23px;
                }
                @include mappy-bp(max-width medium) {
                    display: block;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 23px;
                }
            }
        }
        .grid-l, .grid-r, .quote-box {
            @include mappy-bp(max-width mediumish){
                width: 100%;
                margin: 0;
            }
        }
    }
}