/*********************************************
:: Buttons                             
********************************************/

button {
  outline: none;
}

/*******************************************/
// Default Button

button.button,
input.button {
  display: inline-block;
  height: 40px;
  padding: 0px 12px;

  font-family: $font-family-2;
  font-size: 14px;
  font-weight: $font-semibold;
  line-height: 38px;
  text-decoration: none;
  text-align: center;
  color: $color-1;

  background: transparent;
  border: 1px solid $color-1;
  transition: all .4s ease-in-out;

  &:hover, &:focus, &:active{
    color: $color-1;
  }
}

/*******************************************/
// Button Colors 

.button.white {
  color: black;
  background: white;
  border-color: white;

  @include mappy-bp(medium){
    &:hover, &:focus, &:active{
     background: black;
     color:white;
   }
 }
}

.button.black {
  color: white;
  background: black;
  border-color: black;

  @include mappy-bp(medium){
    &:hover, &:focus, &:active{
     background: white;
     color:black;
   }
 }
}

/*******************************************/
// Disabled Button Styles

.button-disabled,
.button[disabled],
button[disabled],
button[type="submit"][disabled],
input[type="submit"][disabled],
input[type="button"][disabled],
input[type="reset"][disabled]{
	color:white;
	opacity: 0.5;
	cursor: default;
}

.upload-file {
  color: #fff;
  background-color: #000;
  height: 40px;
  text-align: center;

  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input[type="file"] {
    display: none;
  }
}


