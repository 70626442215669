/*********************************************
:: Site Service
********************************************/
.page-list-service {
    min-height: 795px;
    .page-heading {
        h1 {
            right: 30px;
            @include mappy-bp(max-width mediumish) {
                right: 16px;
            }
        }
        margin-bottom: 61px;
        @include mappy-bp(max-width mediumish) {
            margin-bottom: 22px;
        }
    }
    .grid {
        width: 970px;
        margin: auto;
        min-height: 202px;
        display: flex;
        position: relative;
        @include mappy-bp(max-width mediumish) {
            display: block;
            width: 100%;
            padding-left: 29px;
        }
        &:last-child {
            margin-bottom: 60px;
        }
        .grid-l {
            width: 300px;
            position: relative;
            @include mappy-bp(max-width mediumish) {
                display: block;
                width: 100%;
                margin-bottom: 6px !important;
            }
            .title {
                color: $color-2;
                font-family: $font-family-1;
                font-size: 26px;
                font-weight: 900;
                line-height: 32px;
                width: 235px;
                margin-top: -1px;
                @include mappy-bp(max-width mediumish) {
                    font-size: 20px;
                    font-weight: 900;
                    width: 100%;
                }
            }
        }
        .grid-r {
            width: 670px;
            @include mappy-bp(max-width mediumish) {
                display: block;
                width: 100%;
                margin-bottom: 27px !important;
            }
            .content {
                p {
                    color: $color-5;
                    font-family: $font-family-2;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 28px;
                    margin-bottom: 19px;
                    @include mappy-bp(max-width mediumish) {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 22px;
                        margin-bottom: 20px;
                    }
                }
            }
            .link {
                width: 174px;
                height: 38px;
                background-color: $color-7;
                display: flex;
                justify-content: center;
                align-items: center;

                color: #ffffff;
                font-family: $font-family-2;
                font-size: 12px;
                font-weight: 900;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }
        .grid-l, .grid-r, .quote-box {
            @include mappy-bp(max-width mediumish){
                width: 100%;
                margin: 0;
            }
        }
    }
}

.page-service {
    margin-bottom: 75px;
    @include mappy-bp(max-width mediumish) {
        margin-bottom: 30px;
    }
    .block-container {
        @include mappy-bp(medium mediumish) {
            height: 440px;
        }
        @include mappy-bp(max-width medium) {
            height: 515px;
        }
    }
    .block-heading {
        width: 100%;
        min-height: 310px;
        background-color: $color-1;
        position: relative;
        margin-bottom: 46px;
        @include mappy-bp(max-width mediumish) {
            width: 100%;
            display: block;
            padding-top: 30px;
            height: 355px;
        }
        h1 {
            color: #ffffff;
            font-family: $font-family-1;
            font-size: 36px;
            font-weight: 900;
            line-height: 40px;
            margin-bottom: 27px;
            @include mappy-bp(max-width mediumish) {
                font-size: 20px;
                font-weight: 900;
                line-height: 25px;
                margin-bottom: 10px;
            }
        }
        .grid {
            position: relative;
            padding-top: 91px;
            @include mappy-bp(max-width mediumish) {
                padding-top: 0;
            }
            p {
                width: 575px;
                color: #ffffff;
                font-family: $font-family-2;
                font-size: 20px;
                font-weight: 400;
                line-height: 28px;
                margin-bottom: 40px;
                @include mappy-bp(mediumish largeish) {
                    display: block;
                    width: 90%;
                }
                @include mappy-bp(max-width mediumish) {
                    width: 100%;
                    display: block;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                    margin-bottom: 30px;
                }
            }
        }
    }

    .grid {
        display: flex;
        position: relative;
        left: 102px;
        @include mappy-bp(mediumish largeish) {
            left: 0;
            width: 100%;
        }
        @include mappy-bp(max-width mediumish) {
            width: 100%;
            display: block;
            left: 0;
        }
        .grid-l {
            width: 700px;
            @include mappy-bp(mediumish largeish) {
                display: block;
                width: 55%;
                padding-left: 30px;
            }
            @include mappy-bp(max-width mediumish) {
                width: 100%;
                display: block;
            }
            .image {
                display: none;
                background-size: cover !important;
                background-position: center !important;
                background-repeat: no-repeat !important;
                @include mappy-bp(mediumish largeish) {
                }
                @include mappy-bp(medium mediumish) {
                    display: block;
                    width: 60%;
                    height: 255px;
                    margin: auto;
                }
                @include mappy-bp(max-width medium) {
                    display: block;
                    width: 315px;
                    height: 255px;
                    margin: auto;
                }
                @include mappy-bp(max-width tiny) {
                    display: block;
                    width: 100%;
                    height: 255px;
                    margin: auto;
                }
            }
            .content {
                width: 575px;
                @include mappy-bp(mediumish largeish) {
                    width: 100%;
                }
                @include mappy-bp(max-width mediumish) {
                    width: 100%;
                    display: block;
                }
                h1, h2, h3, h4, h5, h6 {
                    color: $color-5;
                    font-family: $font-family-1;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 22px;    
                    margin-bottom: 13px;
                    @include mappy-bp(max-width mediumish) {
                        font-size: 20px;
                        font-weight: 900;
                        line-height: 25px;                    
                    }
                }
                p {
                    color: #000000;
                    font-family: $font-family-2;
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: 0.3px;
                    margin-bottom: 25px;
                    @include mappy-bp(mediumish largeish) {
                        display: block;
                        width: 90%;
                    }
                    @include mappy-bp(max-width mediumish) {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 22px;   
                        margin-bottom: 30px;
                    }
                }
            }
        }
        .grid-r {
            width: 495px;
            @include mappy-bp(mediumish largeish) {
                display: block;
                width: 40%;
            }
            @include mappy-bp(max-width mediumish) {
                width: 100%;
                display: block;
            }
            .image-block {
                width: 370px;
                position: relative;
                right: -10px;
                top: -265px;
                @include mappy-bp(max-width mediumish) {
                    width: 100%;
                    display: block;
                    top: 0;
                    right: 0;
                    left: 0;
                }
                .image {
                    margin-bottom: 36px;
                    @include mappy-bp(max-width mediumish) {
                        display: none;
                    }
                }
                .side-description {
                    width: 370px;
                    height: 120px;
                    @include mappy-bp(max-width mediumish) {
                        width: 100%;
                        display: block;
                    }
                    p {
                        font-family: $font-family-2;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 30px;
                        color: $color-4;
                        @include mappy-bp(max-width mediumish) {
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 23px;
                        }
                    }
                    strong {
                        color: $color-2;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}