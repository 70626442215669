/*********************************************
:: Site Products
********************************************/
.products {
    width: 100%;
    justify-content: center;

    a {
        display: block;

        @include mappy-bp(max-width mediumish) {
            width: 210px;
            margin: auto;
        }

        .product {
            .product-image {
                width: 100%;
                border-bottom: 0.5px solid;
                border-color: $color-grey;
                margin-bottom: 20px;
                padding-bottom: 15px;

                img {
                    width: 160px;
                    margin: auto auto 30px;
                    display: block;
                }
            }

            .product-details {

                .brand {
                    color: #848484;
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 1px;
                    line-height: 15px;
                    margin-bottom: 5px;
                    text-transform: uppercase;
                }

                .name { 
                    color: #0D0D0D;
                    font-size: 16px;
                    margin-bottom: 10px;
                    font-weight: 300;
                }
            }
        }
    }
}

/*********************************************
:: Site Product Featured
********************************************/
.featured-products-text {
    width: 80%;
    margin: auto;
    position: relative;
    margin-bottom: 50px;

    @include mappy-bp(max-width mediumish) {
        width: 100%;
    }

    .text {
        color: #303030;
        font-size: 22px;
        font-weight: 300;
        line-height: 26px;
        text-align: center;
        background-color: #fff;
        width: 18%;
        display: block;
        margin: auto;
        position: relative;
        top: -13px;
        @include mappy-bp(max-width mediumish) {
            font-size: 16px;
            line-height: 16px;
            width: 40%;
        }
    }

    .line {
        border: 0.5px #C5C5C5 solid;
    }

}
/*********************************************
:: Site Product View
********************************************/
.page-view-products {
    border-top: 1.5px solid $color-medium-grey;

    @include mappy-bp(max-width mediumish){
        padding: 15px;
    }

    .page-block {
        .featured-products {
            position: relative;
            width: 80%;
            margin: auto;

            .slick-list {
            }

            .arrow-prev, .arrow-next {
                width: 100px;
                z-index: 9;
                height: 100px;
            }

            .arrow-next {
                transform: rotate(180deg);
                img {
                    position: absolute;
                    top: 90px;

                    @include mappy-bp(max-width mediumish) {
                        left: -10px;
                    }
                    @include mappy-bp(max-width small) {
                        left: -10px;
                    }
                }
            }

            .arrow-prev {
                img {
                    position: absolute;
                    top: 10px;

                    @include mappy-bp(max-width mediumish) {
                        left: -10px;
                    }
                    @include mappy-bp(max-width small) {
                        left: -10px;
                    }
                }
            }

            .slider {
                margin-bottom: 60px;
                position: relative;

                .products {
                    width: 90%;
                    margin: auto;

                    .product {
                        width: 200px;
                        display: table;
                        margin: auto;
                        padding: 10px;
                        @include mappy-bp(max-width mediumish) {
                            margin: auto;
                        }
                    }
                }
            }
        }

        .grid-max-width {
            padding: 0;
            margin-bottom: 80px;
        }

        .grid, .grid-max-width {
            display: flex;
            min-height: 300px;

            @include mappy-bp(max-width mediumish){
                display: block;
            }

            .grid-l {
                width: 581px;

                @include mappy-bp(max-width mediumish){
                    width: 100%;
                }
            }
            .grid-r {
                width: 472px;
                padding-left: 1px;

                @include mappy-bp(max-width mediumish){
                    width: 100%;
                }

                .product-info {
                    .product-brand {
                        color: #848484;
                        font-size: 18px;
                        font-weight: 300;
                        letter-spacing: 2px;
                        line-height: 20px;
                        text-transform: uppercase;
                        margin-top: 6px;
                        margin-bottom: 12px;
                    }
                    .product-name {
                        color: #1F1F1F;
                        font-size: 30px;
                        font-weight: 300;
                        line-height: 35px;
                        margin-bottom: 44px;
                    }
                    .product-actions {
                        margin-bottom: 48px;

                        a {
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 23px;
                        }

                        .wishlist {
                            color: #ABABAB;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 23px;
                            margin-right: 30px;

                            .plus {
                                margin-left: 4px;
                            }
                        }

                        .inquiry {
                            color: #0D0D0D;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 23px;

                            img {
                                position: relative;
                                top: -3px;
                                left: 6px;
                            }
                        }
                    }

                    .product-descriptions {
                        margin-bottom: 30px;

                        .product-tabs {
                            ul {
                                padding: 0;

                                li {
                                    margin: 0;
                                    line-height: 0;
                                    padding-bottom: 12px;
                                    border-bottom: 1px solid #000;

                                    @include mappy-bp(max-width mediumish) {
                                        padding-bottom: 13px;
                                    }

                                    a {
                                        color: #848484;
                                        font-size: 14px;
                                        font-weight: 300;
                                        letter-spacing: 1px;
                                        line-height: 15px;
                                        text-transform: uppercase;
                                        padding-bottom: 8px;

                                        @include mappy-bp(max-width mediumish) {
                                            font-size: 11px;
                                            padding-bottom: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*********************************************
:: Site Product Tabs
********************************************/

.product-tabs {
    .nav-tabs {
        display: flex;
        height: 100%;
        margin-bottom: 30px;

        .nav-item {
            text-align: left;
            display: inline-block;

            .nav-link {
                cursor: pointer;
                a {
                    text-align: center;
                }
            }

            &.active {
                a {
                    border-bottom: 4px solid #000;
                }
            }

            &#description {
                width: 140px;
                @include mappy-bp(max-width mediumish) {
                    width: 30%;
                }
            }
            &#specifications {
                width: 154px;
                @include mappy-bp(max-width mediumish) {
                    width: 30%;
                }
            }
            &#downloads {
                width: 129px;
                @include mappy-bp(max-width mediumish) {
                    width: 30%;
                }
            }
            &#video {
                width: 46px;
                @include mappy-bp(max-width mediumish) {
                    width: 10%;
                    text-align: right;
                }
            }
        }
    }
    .tab-content {
        .tab-pane {
            display: none;

            p {
                color: #0D0D0D;
                font-size: 16px;
                font-weight: 300;
                line-height: 25px;

                @include mappy-bp(max-width mediumish) {
                    font-size: 12px;
                }
            }

            &.active {
                display: block;
            }
        }
    }
}

.image-section {
    .image-preview {
        margin-top: 20px;
        margin-bottom: 30px;

        a {
            margin: auto;
            width: 100%;
            display: block;
            text-align: center;

            img {      
                width: 320px;
                margin: auto;
            }
        }
    }

    .image-list {
        position: relative;
        width: 370px;
        margin: auto;

        @include mappy-bp(max-width mediumish){
            width: 100%;
        }

        .arrow-prev, .arrow-next {
            position: absolute;
            z-index: 999;
            cursor: pointer;

            img {
                width: 10px;
            }
        }

        .arrow-next {
            right: -20px;
            top: 50px;
            transform: rotate(180deg);

            @include mappy-bp(max-width mediumish){
                right: -5px;
            }

        }

        .arrow-prev {
            left: -20px;
            top: 55px;

            @include mappy-bp(max-width mediumish){
                left: -5px;
            }
        }
    }

    .product-slider {
        margin: auto;
        position: relative;
        width: 100%;

        .slide {
            width: 30%;

            .slide-image {
                display: block;
                cursor: pointer;
                padding: 15px;

                img {
                    width: 100%;
                }
            }
        }

    }
}

/*********************************************
:: Site Product List
********************************************/
.page-list-product {
    border-top: 1.5px solid $color-medium-grey;

    .grid-max-width {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        max-width: 1240px;
        padding-top: 11px;

        @include mappy-bp(max-width mediumish) {
            display: block;
        }

        .grid-l {
            width: 20.555%;

            @include mappy-bp(max-width mediumish) {
                width: 100%;
                padding: 0px;
            }

            #aside-filters {
                position: relative;
                z-index: 96;
                display: block;

                @include mappy-bp(max-width mediumish) {
                    position: absolute;
                    top: 77px;
                    right: 0px;
                    border: 1px solid $color-lightest-grey;
                    width: 285px;
                    background-color: #fff;
                    padding-top: 60px;
                    padding-bottom: 60px;
                    display: none;
                }

                .menu-aside-filters-close-icon {
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    display: none;

                    @include mappy-bp(max-width mediumish) {
                        display: block;
                    }
                }
            }

            .category-section {
                margin-left: 45px;

                .category {
                    -webkit-touch-callout: none; /* iOS Safari */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; 

                    &.active {
                        a {
                            font-weight: bold;
                        }
                    }

                    a {
                        font-size: 15px;
                        font-weight: 300;
                    }

                    .icon {
                        cursor: pointer;
                        font-size: 16px;

                        &.active {
                            font-weight: 500;
                        }
                    }
                }

                .subcategories {
                    display: none;

                    &.active {
                        display: block;
                    }

                    .subcategory {
                        margin-left: 12px;

                        li {

                            &.active {
                                a {
                                    font-weight: 400;
                                }
                            }

                            a {
                                font-size: 15px;
                                font-weight: 300;

                            }
                        }
                    }
                }
            }
        }

        .grid-r {
            width: 78.999%;
            padding-right: 40px;

            @include mappy-bp(max-width mediumish) {
                width: 100%;
                padding: 0px;
            }

            .products {
                width: 100%;    
                max-height: 100%;

                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                justify-content: flex-start;

                @include mappy-bp(max-width mediumish) {
                    display: block;
                }

                a {
                    display: block;
                    padding-left: 30px;
                    padding-right: 10px;
                    width: 25%;

                    @include mappy-bp(max-width mediumish) {
                        width: 220px;
                    }

                    &:nth-child(4n){ 
                        .product { 
                            padding: 0px;
                            margin: 0px;
                        }
                    }

                    .product { 
                        display: block;
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }
}


