/*********************************************
:: Main Mob Menu                             
********************************************/
body.open-menu {
    #mobile-menu {
        right: 0;

        .menu-close-icon {
            right: 0; 
        }
    }
}
.hamburger {
    position: absolute;
    right: 20px;
    top: 23px;
    span {
        font-size: 21px;
        color: $color-1;
    }
}
#mobile-menu {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 240px;
    right: 0;
    padding-bottom: 50px;
    background: #ffffff;
    z-index: 999;
    transition: all .25s;
    -webkit-backface-visibility: hidden;
    @include mappy-bp(mediumish){
        display:none;
    }
    @include mappy-bp(max-width mediumish){
        min-height: 550px;
    }
    .menu-close-icon {
        position: absolute;
        top: 3px;
        right: 10px;

        width: 52px;
        height: 56px;

        border: none;
        background-color: none; 
        outline: none;

        span {
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            color: $color-grey;
        }
    }

    .menu-content {
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none; 
        }
        margin-top: 50px;
        position: absolute;
        left: 44px;
        top: 7px;
        li {
            margin: 0;
            min-height: 45px;
            a {
                font-family: $font-family-2;
                font-size: 15px;
                color: $color-1;
                font-weight: 600;
            }
            .sub-menu {
                display: none;
                position: relative;
                background-color: #fff;
                left: 25px;
                z-index: 99;
                margin-bottom: 16px;
                top: 7px;
                li {
                    cursor: pointer;
                    color: #0fb9d1;
                    font-family: $font-family-1;
                    min-height: 38px;
                    a {
                        font-size: 13px;
                        line-height: 30px;
                        font-weight: 600;
                    }
                }
            }
            &.active {
                .parent-menu {
                    color: $color-5 !important;
                }
                .sub-menu {
                    display: block !important;
                }
            }
        }
        .quote-btn {
            position: relative;
            top: 12px;
            left: 1px;
            height: 80px;
            display: block;
            .get-a-quote {
                background: url('/images/svg/get-a-quick-quote-button.svg');
                width: 143px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;

                .text {
                    color: $color-5;
                    font-family: $font-family-1;
                    font-size: 13px;
                    font-weight: 900;
                    margin-right: -2px;
                }
            }
        }
        .disclosure-btn {
            position: relative;
            top: 12px;
            left: 1px;
            height: 80px;
            display: block;
            .disclosure-statement {
                background: url('/images/svg/get-a-quick-quote-button.svg') ;

                width: 143px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;

                .text {
                    color: $color-5;
                    font-family: $font-family-1;
                    font-size: 13px;
                    font-weight: 900;
                    margin-right: -2px;

                }
            }
        }
    }
    ul {
        padding: 0px;
        &.bottom {
            li {
                margin: 0;
                min-height: 44px;
                a {
                    color: $color-5;
                    font-family: $font-family-2;
                    font-size: 13px;
                    font-weight: 700;
                    letter-spacing: 0.6px;
                }
            }
        }
    }
}