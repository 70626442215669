/*********************************************
:: Layout
********************************************/
body {
    font-family: $font-family-1;
    min-width: 320px;
    position: relative;
}

.titanic {
    float: none;
}

.clear {
    clear: both;
}

// Remove blue highlight on click
* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/*******************************************/
// Main <section class="main" role="main">
.main {
    display: block;
    min-height: calc(100vh - #{$header-height-mobile + $footer-height-mobile});
    // max-width: $huge;
    max-width: 100%;
    margin: auto;

    @include mappy-bp(max-width mediumish){
        // min-height: calc(100vh - #{$header-height-desktop + $footer-height-desktop});
        min-height: 200px;
    }
}

/*******************************************/
// Main <aside class="sidebar" role="complementary"> element
.sidebar {
    float: left;
    width: 100%;
    padding: 20px 15px 20px 15px;

    @include mappy-bp(mediumish){
        padding: 45px 0px 45px 0px;
    }
}

/*******************************************/
// Clear
.clear-after {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Content Max Width
.content-max-width {
    position: relative;
    width: 100%;
    padding: 0 6%;

    @include mappy-bp(mediumish){
        max-width: $huge;
        margin: 0 auto;
        padding: 0;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Content Slider Width
.content-slider-width {
    position: relative;
    padding: 0;

    @include mappy-bp(mediumish){
        max-width: 940px;
        margin: 0 auto;
    }
    @include mappy-bp(large){
        max-width: 940px;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Narrow Content Max Width
.content-narrow-width {
    position: relative;
    padding: 0;

    @include mappy-bp(mediumish){
        max-width: 740px;
        margin: 0 auto;
    }
    @include mappy-bp(large){
        max-width: 740px;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

/*******************************************/
// Breakpoints
.mob, .mobile {
    @include mappy-bp(mediumish){
        display: none !important;
    }
}

.desk, .desktop, .hidden-mobile {
    @include mappy-bp(max-width mediumish){
        display: none !important;
    }
}

.show-mobile {
    @include mappy-bp(max-width mediumish){
        display: block !important;
    }
}

.grid {
    @include mappy-bp(max-width mediumish){
        padding-left: 27px;
        padding-right: 27px;
        // margin-top: 25px !important;
        min-height: auto !important;
    }

    .grid-l, .grid-r {
        @include mappy-bp(mediumish){
            width: 100%;
        }
    }
}

.form-field {
    position: relative;
    .chevron {
        position: absolute;
        top: 42px;
        right: 16px;
    }
}

.grid-max-width, .grid-block {
    display: block;
    max-width: 1440px;
    margin: auto;
}

.grid-content {
    max-width: 1175px;
    margin: auto;
}

.overlay {
    display: none;
    position: fixed; 
    width: 100%;
    height: 100%;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 99;
    cursor: pointer;
    opacity: 0.6;
}

@include mappy-bp(mediumish largeish) {
}
@include mappy-bp(medium mediumish) {
}
@include mappy-bp(max-width medium) {
}


/********** SELECT2 *********/

.select2-container {
    width: 100% !important;
}

.select2-container--default {
    input::placeholder, textarea::placeholder, select::placeholder {
        font-size: 12px;
        text-transform: uppercase;
        position: relative;
        top: -3px;
        left: 5px;
        letter-spacing: 1.3px;
        color: #999;
    }
    .select2-selection__choice {
        list-style: none;
    }
    ul {
        list-style: none;
        list-style-type: none;
        li {
            list-style: none;
            list-style-type: none;
            font-size: 12px;
        }
    }
    .select2-search__field {
        margin: 0 !important;
        height: 40px;
    }
    .select2-selection--multiple {
        height: 100%;
        border-radius: 0 !important;
        border-color: #ccc !important;
        overflow: hidden;
        .select2-selection__rendered {
            width: 120%;
            overflow: hidden;
        }
    }
    .select2-selection__placeholder {
        color: #999;
        font-size: 12px !important;
        letter-spacing: 1.5px !important;
        text-transform: uppercase !important;
    }
    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                height: 26px;
                position: absolute;
                top: -4px!important;
                right: 14px;
                width: 20px;
                background-repeat: no-repeat;
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }
    .select2-selection--single, .select2-selection--default {
        width: 100% !important;
        border-radius: 0px !important;
        border: 1px solid $color-medium-grey;
        .select2-selection__arrow {
            height: 26px;
            position: absolute;
            top: 18px !important;
            right: 1px;
            width: 20px;
            background-repeat: no-repeat;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $color-1 !important;
    }
    .select2-results__option {
        text-transform: uppercase;
    }
    .select2-selection--single {
        .select2-selection__rendered {
            color: $color-2 !important;
            font-size: 12px !important;
            letter-spacing: 1.5px !important;
            text-transform: uppercase !important;
        }
    }
}


