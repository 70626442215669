/*********************************************
:: Site Our People
********************************************/

.page-our-people {
    .page-heading {
        h1 {
            right: 30px;
            @include mappy-bp(max-width mediumish) {
                right: 15px;
            }
        }
    }
    .page-block {
        .grid {
            margin-top: 30px;
            @include mappy-bp(max-width mediumish) {
                padding: 0 !important;
                margin-top: 23px !important;
                margin-bottom: 25px;
            }
            .people-list {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                align-content: stretch;
                height: 100%;
                @include mappy-bp(max-width mediumish) {
                    width: 100%;
                }

                .member {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-content: stretch;
                    cursor: pointer;
                    width: inherit;
                    position: relative;
                    @include mappy-bp(max-width mediumish){
                        display: flex;
                        flex-wrap: nowrap;
                    }
                    .side-block-left {
                        width: 105px;
                        height: 280px;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    .side-block-right {
                        width: 105px;
                        height: 280px;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }
                    .blockers {
                        @include mappy-bp(max-width mediumish) {
                            display: none;
                        }
                    }
                    &:nth-child(2n) {
                        @include mappy-bp(max-width mediumish) {
                            flex-direction: row-reverse;
                            .person {
                                margin-right: 0;
                            }
                            .person-desc {
                                margin-right: 5px;
                            }
                        }
                    }
                    &:nth-child(1n) {
                        .person-desc {
                            background-color: $color-2;
                        }
                    }
                    &:nth-child(2n) {
                        .person-desc {
                            background-color: $color-3;
                        }
                    }
                    &:nth-child(3n) {
                        width: 100%;
                        .person-desc {
                            background-color: $color-6;
                        }
                        .blockers {
                            &.side-block-left {
                                background-color: $color-1;
                            }
                            &.side-block-right {
                                background-color: $color-light-grey;
                            }
                            width: 20%;
                            @include mappy-bp(max-width bigmac) {
                                width: 13.1%;
                            }
                            
                            @include mappy-bp(max-width hugeish) {
                                width: 7.1%;
                            }

                            @include mappy-bp(max-width largeish) {
                                width: 7.1%;
                            }

                            @include mappy-bp(max-width mediumish) {
                                width: 20%;
                            }
                        }
                    }
                    &:nth-child(7n) {
                        .person-desc {
                            background-color: $color-3;
                        }
                    }
                    &:nth-child(9n) {
                        .person-desc {
                            background-color: $color-2;
                        }
                    }
                    &:nth-child(6n) {
                        width: 100%;
                        .person-desc {
                            background-color: $color-1;
                        }
                        .blockers {
                            &.side-block-left {
                                background-color: $color-light-grey;
                            }
                            &.side-block-right {
                                background-color: $color-6;
                            }
                        }
                    }
                    .person, .person-desc {
                        width: 270px;
                        height: 280px;
                        display: block;
                        margin-left: 15px;
                        margin-right: 15px;
                        margin-bottom: 30px;
                        @include mappy-bp(max-width largeish){
                            width: 270px;
                            height: 280px;
                            display: block;
                            margin-bottom: 15px;
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                        @include mappy-bp(max-width mediumish){
                            width: 186px;
                            height: 188px;
                            margin-bottom: 5px;
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }

                    .person {
                        background-size: cover !important;
                        background-position: center !important;
                        background-repeat: no-repeat;
                        @include mappy-bp(max-width mediumish){
                            margin-right: 5px;
                        }
                        img {
                            width: 270px;
                            height: 280px;
                        }
                    }

                    .person-desc {
                        background-color: #000;
                        color: #fff;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .person-desc-container {
                            position: absolute;
                            width: 200px;
                            text-align: center;
                            margin: auto;
                            left: 0;
                            right: 0;
                            top: 90px;
                            @include mappy-bp(max-width mediumish){
                                width: 90%;
                                top: auto;
                            }
                            .name {
                                color: #ffffff;
                                font-family: $font-family-1;
                                font-size: 23px;
                                font-weight: 900;
                                margin-bottom: 10px;

                                @include mappy-bp(max-width mediumish){
                                    font-size: 16px;
                                    margin-top: 12px;
                                    margin-bottom: 5px;
                                }
                            }
                            .job-title {
                                color: #ffffff;
                                font-family: $font-family-2;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 22px;
                                text-transform: uppercase;
                                letter-spacing: 2px;
                                margin-bottom: 20px;

                                @include mappy-bp(max-width mediumish){
                                    font-size: 12px;
                                    margin-top: 12px;
                                    margin-bottom: 12px;
                                }
                            }
                            .details {
                                color: #ffffff;
                                font-family: $font-family-2;
                                font-size: 15px;
                                font-weight: 700;
                                border-bottom: 1px solid;
                                width: 48px;
                                margin: auto;
                                padding-bottom: 0px;
                                line-height: 18px;

                                @include mappy-bp(max-width mediumish){
                                    font-size: 12px;
                                    margin-top: 12px;
                                    margin-bottom: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .people-pop-up {
            display: none;
            position: absolute;
            top: 325px;
            z-index: 100;
            width: 1220px;
            min-height: 435px;
            background-color: #fff;
            padding-bottom: 60px;

            left: 0;
            right: 0;
            margin: auto;

            @include mappy-bp(max-width mediumish){
                width: 90%;
                position: absolute;
                top: 170px;
                left: 0;
                right: 0;
                margin: auto;
                padding-bottom: 0px;
            }

            @include mappy-bp(mediumish huge){
                width: 90%;
                position: absolute;
                top: 170px;
                left: 5%;
            }

            .close {
                color: $color-lightest-grey;
                font-size: 40px;
                position: absolute;
                right: 25px;
                top: 30px;
                cursor: pointer;
                z-index: 991;
                @include mappy-bp(max-width mediumish){
                    font-size: 20px;
                    right: -20px;
                    top: -20px;
                }
            }

            .person {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                align-content: stretch;
                position: relative;

                @include mappy-bp(max-width mediumish){
                    display: block;
                }

                .person-avatar {
                    width: 496px;
                    max-width: 100%;
                    position: relative;

                    @include mappy-bp(max-width mediumish){
                        width: 100%;
                        height: 315px;
                        top: 0;
                        margin-top: 0px;
                    }

                    img {
                        width: 380px;
                        height: 385px;
                        display: block;
                        left: 27px;
                        top: 29px;
                        position: relative;
                        @include mappy-bp(max-width mediumish){
                            width: 295px;
                            height: 295px;
                            display: block;
                            margin: auto;
                            left: 0;
                            right: 0;
                        }
                    }
                }

                .person-details {
                    width: 605px;
                    padding-top: 90px;
                    padding-right: 50px;

                    @include mappy-bp(max-width mediumish){
                        width: 100%;
                        margin: auto;
                        padding: 20px;
                    }
                    .name {
                        color: $color-5;
                        font-family: $font-family-1;
                        font-size: 30px;
                        font-weight: 900;
                        margin-bottom: 5px;

                        @include mappy-bp(max-width mediumish){
                            font-size: 20px;
                            font-weight: 900;
                        }
                    }
                    .job-title {
                        color: $color-4;
                        font-family: $font-family-2;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 30px;
                        margin-bottom: 15px;
                        @include mappy-bp(max-width mediumish){
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 23px;
                        }
                    }

                    .details {
                        line-height: 26px;
                        font-weight: 300;
                        margin-bottom: 20px;
                        p {
                            color: #000000;
                            font-family: $font-family-2;
                            font-size: 17px;
                            font-weight: 300;
                            line-height: 24px;
                            letter-spacing: 0.3px;
                        }

                        @include mappy-bp(max-width mediumish){
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }

                    .contacts {
                        display: block;
                        margin-bottom: 20px;

                        .contact {
                            display: inline-block;
                            a {
                                font-size: 18px;
                                font-weight: 400;
                                margin-right: 20px;
                                color: $color-5;
                                @include mappy-bp(max-width mediumish){
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 24px;
                                    &:first-child {
                                        margin-bottom: 20px;
                                    }
                                }
                            }

                            .contact-detail {
                                font-size: 18px;
                                margin-right: 5px;
                                color: $color-4;
                                font-family: $font-family-1;
                                font-weight: 900;
                                @include mappy-bp(max-width mediumish){
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}