/*********************************************
:: Site Why Us
********************************************/
.page-why-us {
    .grid-content {
        margin-top: 61px;
        padding-left: 2px;
        position: relative;
        @include mappy-bp(medium mediumish) {
            margin-top: 45px;
        }
        @include mappy-bp(max-width medium) {
            margin-top: 25px;
        }
        .image {
            height: 370px;
            width: 1070px;
            height: 365px;
            @include mappy-bp(mediumish largeish) {
                width: 85%;
                height: 340px;
                background-size: cover !important;
                background-repeat: no-repeat !important;
            }
            @include mappy-bp(medium mediumish) {
                width: 85%;
                height: 240px;
                background-size: cover !important;
                background-repeat: no-repeat !important;
            }
            @include mappy-bp(max-width medium) {
                width: 85%;
                height: 140px;
                background-size: cover !important;
                background-repeat: no-repeat !important;
            }
        }
        .block {
            width: 205px;
            height: 365px;
            background-color: $color-3;
            position: absolute;
            right: -132px;
            @include mappy-bp(mediumish largeish) {
                width: 13.5%;
                height: 340px;
                background-color: #ccd851;
                position: absolute;
                right: 0;
            }
            @include mappy-bp(medium mediumish) {
                width: 13.5%;
                height: 240px;
                background-color: #ccd851;
                position: absolute;
                right: 0;
            }
            @include mappy-bp(max-width medium) {
                width: 13.5%;
                height: 140px;
                background-color: #ccd851;
                position: absolute;
                right: 0;
            }
        }
    }
    .grid {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        min-height: 600px;
        margin-bottom: 30px;
        margin-top: 44px;
        position: relative;
        @include mappy-bp(max-width mediumish) {
            width: 100%;
            display: block;
            margin-top: 25px;
        }
        .grid-l {
            width: 500px;
            position: relative;
            @include mappy-bp(mediumish largeish) {
                padding: 20px;
            }
            @include mappy-bp(max-width mediumish) {
                width: 100%;
                display: block;
            }
            .text {
                width: 500px;
                position: absolute;
                left: 0;
                @include mappy-bp(mediumish largeish) {
                    position: relative;
                }
                @include mappy-bp(max-width mediumish) {
                    position: relative;
                    width: 100%;
                    display: block;
                }
            }
            .lead-copy {
                color: $color-1;
                font-family: $font-family-2;
                width: 370px;
                height: 90px;
                font-size: 24px;
                font-weight: 600;
                line-height: 30px;
                @include mappy-bp(max-width mediumish) {
                    width: 100%;
                    display: block;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 23px;
                    height: auto;
                    margin-bottom: 25px;
                }
            }
        }
        .grid-r {
            width: 570px;
            @include mappy-bp(mediumish largeish) {
                padding: 20px;
            }
            @include mappy-bp(max-width mediumish) {
                width: 100%;
                display: block;
            }
            .content {
                p {
                    color: #000000;
                    font-family: $font-family-2;
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: 0.3px;
                    margin-bottom: 25px;
                    @include mappy-bp(max-width mediumish) {
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 21px;
                        letter-spacing: 0.25px;
                    }
                }
                ul {
                    padding: 0;
                    margin-left: 19px;
                    margin-bottom: 25px;
                    margin-top: -5px;
                    li {
                        list-style-type: disc;
                        color: #232323;
                        font-family: $font-family-2;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 27px;
                        letter-spacing: 0.3px;
                        margin: 0;
                        padding-left: 3px;
                        padding-top: 1px;
                        padding-bottom: 0;
                        @include mappy-bp(max-width mediumish) {
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 21px;
                            letter-spacing: 0.25px;
                        }
                    }
                }
            }
        }
        .grid-l, .grid-r, .quote-box {
            @include mappy-bp(max-width mediumish){
                width: 100%;
                margin: 0;
            }
        }
    }
}