/*********************************************
:: Claims
********************************************/
.page-claims {
    min-height: 600px;
    margin-bottom: 63px;
    @include mappy-bp(max-width medium) {
        min-height: 675px;
    }
    .page-heading {
        h1 {
            right: 31px;
            @include mappy-bp(max-width mediumish) {
                right: 16px;
            }
        }
    }
    .claim-section {
        padding-top: 61px;
        display: block;
        width: 570px;
        margin: auto;
        @include mappy-bp(max-width medium) {
            width: 100%;
            display: block;
            padding-top: 25px;
            padding-left: 27px;
            padding-right: 27px;
        }
        .content {
            margin-bottom: 38px;
            @include mappy-bp(max-width mediumish) {
                margin-bottom: 30px;
            }
            p {
                color: $color-5;
                font-family: $font-family-2;
                font-size: 20px;
                font-weight: 400;
                line-height: 28px;
                @include mappy-bp(max-width mediumish) {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }
        .contact-form {
            display: inline-block;
            @include mappy-bp(max-width mediumish) {
                display: block;
            }
            .two-column {
                .form-field {
                    &:nth-child(1) {
                        width: 247px;
                        margin-right: 12px;
                        @include mappy-bp(max-width medium) {
                            width: 100%;
                            display: block;
                        }
                    }
                    &:nth-child(2) {
                        width: 311px;
                        @include mappy-bp(max-width medium) {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
            label {
                color: #000000;
                font-family: $font-family-2;
                font-size: 13px;
                font-weight: 300;
                line-height: 20px;
                margin-bottom: 3px;
            }
            .contact-form {
                margin-top: -2px;
            }
            .form-field {
                margin-bottom: 0px;
                padding-bottom: 14px;
            }
            .form-submit {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 109px;
                height: 38px;
                background-color: $color-7;
                a {
                    color: #ffffff;
                    font-family: $font-family-2;
                    font-size: 12px;
                    font-weight: 900;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    position: relative;
                    left: 0;
                    width: 100%;
                }
            }
        }
    }
}