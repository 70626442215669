/*********************************************
:: Site Header
********************************************/
.site-header {
    height: $header-height-desktop;
    @include mappy-bp(max-width mediumish) {
        height: $header-height-mobile;
    }
    @include mappy-bp(mediumish largeish) {
        padding-left: 30px;
        padding-right: 30px;
    }
    .grid-content {
        display: block;
        margin: auto;
        height: $header-height-mobile;
        position: relative;
        .logo {
            position: absolute;
            top: 44px;
            left: 1px;
            @include mappy-bp(max-width mediumish) {
                top: 0px;
                left: 1px;
            }
            img {
                width: 169px;
                height: 46px;
                @include mappy-bp(max-width mediumish) {
                    width: 100px;
                    position: relative;
                    left: 18px;
                    top: 10px;
                }
            }
        }

        .header-buttons {
            position: absolute;
            top: 27px;
            right: 4px;

            display: inline-flex;
            justify-content: space-between;


            .quote-btn {
                @include mappy-bp(max-width mediumish) {
                    display: none;
                }

                .get-a-quote {
                    background: url('/images/svg/get-a-quick-quote-button.svg');
                    width: 143px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .text {
                        position: relative;
                        color: $color-5;
                        font-family: $font-family-1;
                        font-size: 13px;
                        font-weight: 900;
                        margin-right: -2px;
                    }
                }
            }

            .disclosure-btn {
                @include mappy-bp(max-width mediumish) {
                    display: none;
                }

                .disclosure-statement {
                    background: url('/images/svg/disclosure-statement-button.svg') no-repeat;
                    width: 143px;
                    height: 36px;
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .text {
                        color: $color-5;
                        font-family: $font-family-1;
                        font-size: 13px;
                        font-weight: 900;

                    }
                }
            }
        }

        nav {
            max-width: 560px;
            position: absolute;
            right: 3px;
            top: 80px;
            .middle {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-pack: justify;
                justify-content: space-between;
                width: 545px;
                @include mappy-bp(max-width mediumish) {
                    display: none;
                }
                li {
                    min-width: 50px;
                    margin-right: 23px;
                    margin-bottom: 0px;
                    .sub-menu {
                        display: none;
                        position: absolute;
                        background-color: #fff;
                        z-index: 99;
                        width: 132px;
                        left: -24px;
                        padding: 8px 35px 15px 25px;
                        li {
                            cursor: pointer;
                            color: #0fb9d1;
                            font-family: $font-family-1;
                            a {
                                font-size: 14px;
                                line-height: 30px;
                            }
                        }
                    }
                    &.active {
                        .parent-menu {
                            color: $color-5 !important;
                        }
                        .sub-menu {
                            display: block !important;
                        }
                    }
                    &:nth-child(1) {
                        margin-right: -15px;
                    }
                    &:nth-child(2) {
                        margin-right: 5px;
                        .sub-menu {
                            width: 132px;
                            left: -24px;
                            padding: 8px 35px 15px 25px;
                        }
                    }
                    &:nth-child(3) {
                        margin-right: 2px;
                        .sub-menu {
                            width: 124px;
                            left: -25px;
                            padding: 8px 15px 15px 25px;
                        }
                        &.active {
                            margin-top: 1px;
                        }
                    }
                    &:nth-child(4) {
                        margin-right: -8px;
                    }
                    &:nth-child(5) {
                        margin-right: -6px;
                    }
                    &:last-child {
                        margin-right: 0px;
                    }
                    a {
                        width: 45px;
                        height: 21px;
                        color: $color-1;
                        font-family: $font-family-2;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    img {    
                        position: absolute;
                        top: 11px;
                        right: -12px;
                    }
                }
            }
        }
    }
}
/********************************************/