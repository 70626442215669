/*********************************************
:: Testimonials
********************************************/
.page-testimonials {
    margin-bottom: 85px;
    .page-heading {
        h1 {
            right: 30px;
            @include mappy-bp(max-width mediumish) {
                right: 16px;
            }
        }
    }
    .testimonials {
        width: 970px;
        margin-top: 60px;
        position: relative;
        left: 102px;
        @include mappy-bp(max-width mediumish) {
            width: 100%;
            left: 0;
            right: 0;
            display: block;
            padding-left: 27px;
            padding-right: 27px;
            margin-top: 24px;
        }
        .testimonial {
            display: flex;
            min-height: 100px;
            margin-bottom: 34px;
            @include mappy-bp(max-width mediumish) {
                width: 100%;
                display: block;
            }
            .author {
                width: 248px;
                @include mappy-bp(max-width mediumish) {
                    width: 100%;
                    display: block;
                    margin-bottom: 10px;
                }
                .name {
                    width: 170px;
                    color: $color-5;
                    font-family: $font-family-1;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 25px;
                    @include mappy-bp(max-width mediumish) {
                        width: 100%;
                        display: block;
                        font-size: 20px;
                        font-weight: 900;
                    }
                }
            }
            .quote {
                width: 720px;
                position: relative;
                @include mappy-bp(max-width mediumish) {
                    width: 100%;
                    display: block;
                }
                .quote-img {
                    position: absolute;
                    top: 4px;
                    left: 1px;
                }
                .content {
                    width: 670px;
                    position: relative;
                    left: 52px;
                    padding-top: 1px;
                    @include mappy-bp(max-width mediumish) {
                        width: 90%;
                        display: block;
                        left: 30px;
                    }
                    p {
                        color: #000000;
                        font-family: $font-family-2;
                        font-size: 17px;
                        font-weight: 300;
                        line-height: 24px;
                        letter-spacing: 0.3px;
                        @include mappy-bp(max-width mediumish) {
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 21px;
                            letter-spacing: 0.25px;
                        }
                    }
                }
            }
        }
    }
}